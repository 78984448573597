<script>
import NavbarComponent from '../../components/v2/NavbarComponent.vue';
import axios from 'axios';
export default {
    name: 'FavoriteView',
    components: {
        NavbarComponent,
    },

    data () {
        return {
            campaigns: [],
        }
    },

    beforeMount() {
        this.getFavoriteCampaign();
    },

    methods: {
        goToDetail(id) {
            console.info('-- detail --');
            this.$router.push('/campaign/' + id);
        },

        getFavoriteCampaign() {
            axios.get(process.env.VUE_APP_API + 'campaigns/favorite', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    data.forEach(element => {
                        this.campaigns.push(element);
                    });
                }
            })
        }
    }
}

</script>

<template>
    <div>
        <div class="row">
            <div class="col my-auto">
                <h3>Donasi Favorit Anda</h3>
            </div>
        </div>

        <div class="mt-2">
            <div class="row" style="border-radius: 6px;" v-for="index in campaigns" :key="index.id"  @click="goToDetail(index.campaign_id)">
                <div class="col p-2">
                    <div class="row bg-white px-2 py-2">
                        <div class="col-auto">
                            <img style="border-radius: 6px; width: 60px; height: 60px; object-position: center;" :src="index.cover_img" class="img-fluid" alt="">
                        </div>
                        <div class="col my-auto text-truncate">
                            <div>
                                <div>
                                    <h5 class="text-truncate">{{ index.title }}</h5>
                                </div>
                                <div class="text-muted">
                                    {{ index.location }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br>
        <br>
        <br>
        <NavbarComponent/>
    </div>
</template>