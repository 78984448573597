<template>
    <nav class="navbar navbar-white bg-white navbar-expand fixed-bottom" style="border-top: 1px solid #667085;">
        <ul class="navbar-nav nav-justified w-100">
            <li class="nav-item">
                <div @click="goToMenu('/')" class="text-muted">
                    <div>
                        <font-awesome-icon  icon="fa-solid fa-home" />
                    </div>
                    <div class="font-donation-small">
                        Home
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div @click="goToMenu('/donations')" class="text-muted">
                    <div>
                        <font-awesome-icon  icon="fa-solid fa-clock" />
                    </div>
                    <div class="font-donation-small">
                        Riwayat
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div @click="goToMenu('/favorite')" class="text-muted">
                    <div>
                        <font-awesome-icon  icon="fa-solid fa-heart" />
                    </div>
                    <div class="font-donation-small">
                        Favorit
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div @click="goToMenu('/profile')" class="text-muted">
                    <div>
                        <font-awesome-icon  icon="fa-solid fa-user" />
                    </div>
                    <div class="font-donation-small">
                        Profil
                    </div>
                </div>
            </li>
        </ul>
    </nav>
</template>
<script>
import { faClock, faHeart, faHome, faUser } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faHome, faClock, faHeart, faUser)

export default {
    name: 'NavbarComponent',
    methods: {
        goToMenu(menu) {
            this.$router.replace(menu);
        }
    }
}
</script>

<style scoped>
router-link {
    text-decoration: none;
}
</style>